$scrollbar-color: $primary-color;
$scrollbar-background: $bg-color;
$scrollbar-width: 8px;

::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: $scrollbar-background;
}

::-webkit-scrollbar:vertical {
    width: $scrollbar-width;
    background-color: $scrollbar-background;
}

::-webkit-scrollbar:horizontal {
    display: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    padding: 10px;
    background-color: $scrollbar-color;
}