// COLORS
$primary-color: #77f73b;
$secondary-color: #ED2779;
$third-color: #FECA2E;
$black-color: #000000;
$white-color: #FFFFFF;
//LINKS
$link-hover-color: #fff;
$link-color: #fff;

$link-color: #fff;

// BACKGROUND
$bg-color: #0F0F0F;

// TEXT
$typography-color: #fff;
$typography-header-color: $primary-color;

