@import "Colors";
@import "Variables";
@import "Mixins";
@import "Fonts";
@import "Global";
@import "Buttons";
@import "Scrollbar";
@import "Mobile";

#root {
  background-color: #191919;
  height: 100%;
  width: auto;
}

html,
body {
  background-color: #191919;
  min-height: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  width: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.background{
  background-repeat: no-repeat;
  &.third{
    background-color: $third-color;
  }
  max-width: 100%;
}

.appear{
  animation: appear 0.7s ease-in alternate;
}

.accord {
  background-color: #141414 !important;
  border-radius: 8px !important;
  cursor: pointer;
  padding: 18px 24px;
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content !important;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translate(0, 90px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.background {
  background-color: $bg-color;
  &.scrollable {
    background-attachment: fixed;
    background-size: cover;
    background-position: inherit;
  }
  max-width: 100%;
}

.logoGrid {
  width: 50%;
  min-height: 480px;
  background: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.RegistrationBoxWrap {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.RegistrationBox {
  max-width: 550px;
  max-width: 550px;
  width: -webkit-fill-available;
  max-height: 700px;
  height: fit-content;
  min-height: 480px;
  background-color: #191919;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 0px 20px #0000009b;
  border-radius: 5px;
  padding: 24px;
}

.logincontainer {
  justify-content: center !important;
  max-width: 850px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  box-shadow: rgb(0 0 0 / 61%) 0px 0px 20px !important;
  border-radius: 5px !important;
  height: fit-content;
  min-height: 480px;
  position: relative;
}

.LoginBox .MuiInputBase-root {
  color: black !important;
  background-color: white !important;
}
.Form .MuiFormControlLabel-label {
  font-size: 1.2rem;
}
.LoginForm {
  background: #292929;
  width: 50%;
  height: 100%;
  display: grid;
}

.DataGrid {
  display: flex;
  flex-direction: row;
}

.DataBox {
  background: #363636;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 40px 10px;
  width: 95%;
}
.DataBoxTrafic {
  background: #363636;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 40px 10px;
  width: 100%;
}
.DataBoxSupport {
  background: #363636;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 40px;
  width: 50%;
}
.DataBoxComunication {
  background: #363636;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 10px;
  margin-top: 10px;
  padding: 40px;
  width: 100%;
}
.DataBoxPayout {
  background: #363636;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  border-radius: 10px;
  margin-top: 10px;
  padding: 40px;
  width: 33%;
}

.SupportGrid {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.SupportGrid .support {
  display: flex;
  width: 100%;
  justify-content: center;
}

.TrafficData {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  padding: 0px 20px;
}
.TrafficData .MuiInputBase-root {
  color: black !important;
}
.MainSaveButton {
  background-color: #77f73b !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  width: 20%;
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.landing_save {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: center;
}

.data_headers {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.data_headers_support {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 50%;
}

.Dialog_verify .MuiDialog-paper {
  text-align: center !important;
  margin: 0 auto !important;
  background-image: unset !important;
  padding: 20px 15px;
  background: #191919 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 20px #00000080 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  border: unset;
  max-width: 630px;
  width: -webkit-fill-available;
  max-height: 518px;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  overflow: unset;
}

.Dialog_verify .title {
  text-align: left;
  font: normal normal 800 26px/32px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.Dialog_verify .Input {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: 90%;
}

.DialogContent {
  overflow: unset;
  width: 80%;
}

.Dialog_verify .landingPageText {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.Dialog_verify .landingPageOutText {
  text-align: left;
  font-size: 0.8rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.pop-up_verify .MuiBackdrop-root {
  background-color: black;
  opacity: 0.8 !important;
}

.LandingSelect {
  width: 90%;
  height: 35px;
  background-color: white;
  color: black !important;
}

.LandingSelect .MuiSelect-icon {
  color: black !important;
}

.MuiOutlinedInput-input.Mui-disabled{
  -webkit-text-fill-color: black !important;
}

.UserSelect {
  width: 40%;
  height: 35px;
  background-color: white;
  color: black !important;
  border-radius: 5px;

}

.UserSelect .MuiSelect-icon {
  color: black;
}
.UserSelect .MuiInput-root{
  color: black !important;
  align-items: center;
}
.UserSelect .MuiAutocomplete-clearIndicator, .UserSelect .MuiAutocomplete-popupIndicator {
  color:black !important
}

.Campaign_delete {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  height: 35px;
}

.Support_selector {
  display: flex;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  justify-content: flex-start;
  width: 90%;
}

.support_subject {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 50px;
}

.support_respond_type {
  width: 50%;
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.subject {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 50%;
}
.subject .MuiInputBase-root {
  color: black !important;
}

.payoutField {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  flex-direction: row;
}
.payoutField .MuiInputBase-root {
  color: black !important;
}
.payoutField_user {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  flex-direction: column;
}
.payoutField_user .MuiInputBase-root {
  color: black !important;
}

.payoutAmmount {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 15%;
  margin-bottom: 25px;
}

.MuiPaginationItem-root.Mui-selected {
  background-color: white !important;
  color: black;
}

.MuiPaginationItem-root {
  border: 2px solid white !important;
  display: flex;
}

.MuiPaginationItem-root.MuiPaginationItem-previousNext {
  border: unset !important;
}

.stats_type {
  width: 25%;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
}

.stats_time {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
}

.stats_time_grid {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  align-content: center;
  width: 50%;
}

.calendarWrap {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
  z-index: 999;
}

input.inputBox {
  font-size: 20px;
  padding: 5px 8px 4px 8px;
  border-radius: 3px;
  background-color: white;
  color: black;
  border-width: 0px;
  width: 200px;
}

.wrapper {
  position: relative;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
}

.filter_result {
  width: 8%;
  height: 35px;
  background-color: white;
  color: black !important;
}

.filter_result .MuiSelect-icon {
  color: black;
}

.сommunicationTypeSelector {
  width: 100%;
  height: 35px;
  background-color: white;
  color: black !important;
  display: flex;
  justify-content: center;
}
.сommunicationTypeSelector .MuiSelect-icon {
  color: black;
}

.PayoutSelect {
  width: 100%;
  height: 35px;
  background-color: black;
  color: #77f73b !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.PayoutSelect .MuiSelect-icon {
  color: #77f73b;
}

.PayoutSelectCrypto {
  width: 100%;
  height: 35px;
  background-color: black;
  color: #77f73b !important;
}

.PayoutSelectCrypto .MuiSelect-icon {
  color: #77f73b;
}

.Payout_type {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  width: 70%;
}
.PayoutButton {
  background-color: #77f73b !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  width: 20%;
}

.DisabledLink .Mui-disabled {
  opacity: 1 !important;
  -webkit-text-fill-color: #77f73b !important;
}
.snackBarCopy .MuiSnackbarContent-root {
  background-color: #77f73b !important;
  min-width: 160px;
  justify-content: center;
}

.MuiInput-root.Mui-error:after {
  border-bottom-color: #77f73b !important;
}
.faq_link{
  color: white;
  text-decoration: underline !important;
}

.FaqBox {
  width: 50%;
  padding: 40px;
  border-radius: 10px;
  background-color: black;
}
.styles_faq-row-wrapper__3vA1D {
  background-color: black !important;
  width: 100% !important;
}
.styles_faq-row-wrapper__3vA1D h2 {
  margin: 0;
  color: var(--title-text-color, white) !important;
  font-size: var(--title-text-size, 30px);
}
.styles_faq-row__2YF3c .styles_row-title__1YiiY {
  color: white !important;
}
.styles_row-title__1YiiY .styles_icon-wrapper__2cftw {
  color: white !important;
}
.styles_row-title__1YiiY .styles_icon-wrapper__2cftw svg {
  fill: white !important;
}
.styles_row-content__QOGZd .styles_row-content-text__2sgAB {
  color: white !important;
}
.ProfileTab {
  font-size: 20px !important;
  text-transform: capitalize !important;
}

@media screen and (max-width: 575px) {
  .profileTab-container .MuiTabs-flexContainer {
    flex-flow: wrap;
  }

  .profileTab-container .MuiTabs-indicator {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .ProfileTab {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 575px) {
  .profileTab-container .MuiTabs-flexContainer {
    flex-flow: wrap;
  }

  .profileTab-container .MuiTabs-indicator {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .ProfileTab {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 575px) {
  .profileTab-container .MuiTabs-flexContainer {
    flex-flow: wrap;
  }

  .profileTab-container .MuiTabs-indicator {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .ProfileTab {
    font-size: 15px !important;
  }
}
.MuiTabs-root .MuiTabs-indicator {
  background-color: #77f73b;
}

.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: #77f73b;
}

.Dialog_highlight .MuiDialog-paper {
  text-align: center !important;
  margin: 0 auto !important;
  background-image: unset !important;
  /* padding: 20px 15px; */
  background: #131313 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  border: unset;
  max-width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: unset;
}
.Dialog_transaction .MuiDialog-paper {
  text-align: center !important;
  margin: 0 auto !important;
  background-image: unset !important;
  /* padding: 20px 15px; */
  background: #131313 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  border: unset;
  max-width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: unset;
  max-width: 600px;
}

.pop-up_verify .MuiBackdrop-root {
  background-color: black;
  opacity: 0.8 !important;
}

.normalLineHeight {
  line-height: normal;
}
.react-grid-multiline-content .react-grid-Cell__value {
  white-space: normal !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: none !important ;
  border-width: 0px !important;
}
.MuiCheckbox-root.Mui-checked {
  color: #77f73b !important;
}

.Trafic_info_grid {
  padding-top: 50px !important;
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  align-items: center;
}
.Mansory_img {
  object-fit: cover !important;
  height: unset !important;
  width: 100%;
}

.preroll{
  text-align: center !important;
    margin: 0 auto !important;
    background-image: unset !important;
    /*  padding: 20px 15px; */
    background: #131313 0% 0% no-repeat padding-box !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
    -moz-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
    box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
    border-radius: 10px !important;
    opacity: 1 !important;
    border: unset;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: unset;
}
.i_frame{
  text-align: center !important;
    margin: 0 auto !important;
    /*  padding: 20px 15px; */
    -webkit-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
    -moz-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
    box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
    border-radius: 10px !important;
    opacity: 1 !important;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: unset;
    width: 100%;
    height: 100%;
}
.i_frame .MuiDialog-paper {
  text-align: center !important;
  margin: 0 auto !important;
  background-image: unset !important;
  /* padding: 20px 15px; */
  background: #131313 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  border: unset;
  max-width: 100%;
  height: 100%;
  height: -moz-fit-content;
  /* height: fit-content; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: unset;
  width: 100%;
}
.i_frame .MuiDialog-container {
  width: 90%;
}
.container{
  display: flex;
  justify-content: center;
}
#menu-appbar .MuiPopover-paper{
  top: -15px !important;
}
.modal {
  border-radius: 10px;
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 0 0 2rem 12px rgb(0 0 0 / 50%);
  border: 1px solid rgb(18, 18, 18);

  &.preview {
    padding: unset !important;
  }
  
}

.MuiModal-root{
  & .MuiOutlinedInput-notchedOutline{
    border-width: 1px !important;
  }
}