.link {
  margin: 2px !important;
  margin-left: unset !important;
  margin-right: 5px !important;
}

@media screen and (max-width: 900px) {
    .avatar {
      width: 54px !important;
      height: 54px !important;
    }
    .avatar_grid{
        padding: 0px !important;
        background-color: unset !important;
    }
  }
.avatar {
  margin: 5px;
  width: 64px;
  height: 64px;
  background-color: #ffd800 !important;
  color: white !important;
}
.avatar_grid{
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #363636;
    margin: 10px 0px;
    padding: 0px 15px;
}

.online {
  border: 3px solid #77f73b;
}

.status {
  border: 3px solid #f7513b;
}

