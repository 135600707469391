.btn{
    border: none;
    font-size: $font-size-md !important;
    font-family: $font-gilroy-bold !important;
    font-weight: normal !important;
    line-height: 1.2 !important;
    border-radius: 5px !important;
    height: 100%;
    padding: 10px 24px !important;
    -webkit-font-smoothing: subpixel-antialiased;
    transition: all 0.15s linear !important;
}

.btn:hover{
    filter: brightness(1.10);
}
.btn:active{
    filter: brightness(1.15);
}

.btn-primary{
    color: $black-color !important;
    background-color: $primary-color !important;
    box-shadow: 0px 3px 20px #FF00E2C6;
}

.btn-secondary{
    background-color: transparent !important;
    font-size: $font-size-lg !important;
    padding: 14px 28px !important;
    border-radius: 5px !important;

    &.yellow{
        border: 2px solid $third-color;
        color: $third-color !important;
    }
    &.black{
        border: 2px solid $black-color;
        color: $black-color !important;
        
    }
}

.btn-third{
    color: $primary-color !important;
    background-color: transparent !important;
    border: 3px solid $primary-color;
}

.btn-third-borderless{
    color: $primary-color !important;
    background-color: transparent !important;
}