@media screen and (max-width: 900px) {
  #root .MuiContainer-root {
    flex-direction: column !important;
  }
  .logoGrid {
    display: none !important;
  }
  .LoginForm {
    width: 100% !important;
    height: 100% !important;
    display: grid;
  }
  .hide-mobile {
    display: none !important;
  }
  .DataBox{
    width: 100% !important;
  }
  .DataBoxSupport{
    width: 100% !important;
    padding: 20px !important;
  }
  .DataBoxComunication{
    flex-direction: column !important;
    align-items: center !important;
    padding: 20px !important;
  }
  .DataBoxPayout{
    width: 100% !important;
  }
  .DataGrid {
    display: flex !important;
    flex-direction: column !important;
    max-width: 100% !important;
  }

  .DataGrid .data {
    max-width: 100% !important;
  }

  .TrafficData {
    flex-direction: column;
  }

  .MainSaveButton {
    width: 50% !important;
  }
.Trafic_info_grid{
    align-items: flex-start !important;
}
  .data_headers {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .data_headers_support{
    flex-direction: column !important;
    align-items: flex-start !important;
    width: 100% !important;
  }

  .data_headers .green_text {
    font-size: 1rem !important;
  }
  .data_headers_support .green_text {
    font-size: 1rem !important;
  }

  .DialogContent {
    width: 100% !important;
  }

  .Dialog_verify .Input {
    width: 100% !important;
  }

  .LandingSelect {
    width: 100% !important;
  }

  .Campaign_delete {
    justify-content: center !important;
  }

  .support_subject {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    width: 100% !important;
    gap: 50px;
  }

  .support_respond_type {
    width: 100% !important;
  }

  .Support_selector {
    width: 100% !important;
  }
  .subject {
    width: 100% !important;
  }
  .filter_result {
    width: 50% !important;
  }

  .landing_save {
    margin-bottom: 30px !important;
  }
  .payoutAmmount {
    width: 100% !important;
  }
  .PayoutSelect {
    width: 100% !important;
  }
  .payoutField {
    width: 100% !important;
  }
  .payoutField_user {
    width: 100% !important;
  }
  .PayoutButton {
    width: 70% !important;
  }
  .Run_compaign {
    font: normal normal bold 1.2rem Effra !important;
  }

  .new_campaign {
    padding: 5px 10px !important;
    font-size: 0.8rem !important;
    font-weight: bold !important;
  }
  .stats_header {
    flex-direction: column-reverse !important;
  }
  .stats_choise {
    margin-top: 25px !important;
  }
  .stats_body {
    flex-direction: column !important;
  }
  .stats_type {
    width: 100% !important;
  }
  .calendarWrap {
    flex-direction: column !important;
  }
  .calendarElement {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
  .date_time_button {
    margin-top: 15px !important;
    margin-left: 0px !important;
  }
  .period_select {
    flex-direction: column !important;
    margin-bottom: 10px !important;
  }
  .сommunicationTypeSelector {
    width: 100% !important;
  }
  .FaqBox {
    width: 100% !important;
    padding: 20px !important;
  }
  .preroll{
    text-align: center !important;
      margin: 0 auto !important;
      background-image: unset !important;
      /*  padding: 20px 15px; */
      background: #131313 0% 0% no-repeat padding-box !important;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
      -moz-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
      box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
      border-radius: 10px !important;
      opacity: 1 !important;
      border: unset;
      height: fit-content;
      max-width: 370px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: unset;
  }
  .i_frame{
    text-align: center !important;
      margin: 0 auto !important;
      background-image: unset !important;
      /*  padding: 20px 15px; */
      background: #131313 0% 0% no-repeat padding-box !important;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
      -moz-box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
      box-shadow: 0px 0px 20px 1px rgba(119, 247, 59, 1);
      border-radius: 10px !important;
      opacity: 1 !important;
      border: unset;
      max-width: 370px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: unset;
  }
}


@media screen and (orientation:portrait) {
  .background{
      background-size: cover !important;
      background-position: 0 100%;
      &.sreser{
          background-position: 400px 1200px !important;
      }
  }
  /* Portrait styles */
  @media screen and (max-width: 900px) {

  }

  @media screen and (max-width: 450px) {

  }
}


/* Landscape */

@media screen and (orientation:landscape) {

  /* Landscape styles */
  @media screen and (max-width: 900px) {

  }
}
/* Landscape styles */
@media screen and (max-width: 1590px) {
  .background{
    padding: 0 50px;
  }
}
/* Landscape styles */
@media screen and (max-width: 1250px) {
  .background{
    padding: 0 25px;
  }
  .typography.xlg{
    font-size: $font-size-xlg - 5 !important;
  }
  .typography.xxlg{
    font-size: $font-size-xxlg - 5 !important;
  }
  .typography.xxxlg{
    font-size: $font-size-xxxlg - 5 !important;
  }
}

/* Landscape styles */
@media screen and (max-width: 900px) {
  .background{
    padding: 0 25px;
  }
  .typography.xlg{
    font-size: $font-size-xlg - 7 !important;
  }
  .typography.xxlg{
    font-size: $font-size-xxlg - 10 !important;
  }
  .typography.xxxlg{
    font-size: $font-size-xxxlg - 20 !important;
  }

  .btn{
    font-size: $font-size-md !important;
  }
}

/* Landscape styles */
@media screen and (max-width: 450px) {
  .background{
    padding: 0;
  }
  .typography.xlg{
    font-size: $font-size-xlg - 12 !important;
  }
  .typography.xxlg{
    font-size: $font-size-xxlg - 15 !important;
  }
  .typography.xxxlg{
    font-size: $font-size-xxxlg - 39 !important;
  }

  .logo{
    width: 135px !important;
  }
  #about, #price, #footer {
    padding: 0 25px;
    }
  .Containter_mobile{
    padding: 0 20px
  }
}